
<template>
    <el-select :placeholder="placeholder" v-model="binds" :size="size" :multiple="multiple" :multiple-limit="limit" filterable allow-create default-first-option>
        <el-option  v-for="(val,index) in options" :key="index" :label="val" :value="val" />
    </el-select>
</template>

<script>
export default {
    name: "page-tags",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /* 初始值 */
        size: {type: String,default:''},
        value: {type: String,default:''},
        split: {type: String,default: '-'},
        limit: {type: Number,default:0},
        multiple: {type: Boolean,default:true},
        options: {type: Array ,default: ()=>{return []}},
        placeholder:{type: String,default:'搜索或添加'}
    },
    data() {
        return {
            binds:[]
        };
    },
    created() {
        if( this.value ){
			this.binds = this.value.split(this.split);
        }
    },
    methods: {
       
    },
    watch: {
        value(value) {
            this.binds = value?value.split(this.split):[];
        },
        binds(vals) {
            this.$emit('change', typeof(vals)=='string'?vals:vals.join(this.split));
        }
    }
};
</script>